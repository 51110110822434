//
//
//
//
//
//
//
//
//
//
//

export default {
  head() {
    return {
      title: "Page not found"
    };
  }
};
